import React, { useState } from "react";
import "./App.css";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import portraitImage from "./images/thuy.jpeg";

const navigation = [
  { name: "About", href: "#" },
  { name: "Contact", href: "#" },
];

function App() {
  return (
    <div className="App">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 lg:mt-16">
        <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
          <div className="lg:pl-20">
            <div className="max-w-xs px-2.5 lg:max-w-none">
              <img
                src={portraitImage}
                alt=""
                sizes="(min-width: 1024px) 32rem, 20rem"
                className="aspect-square lg:rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800"
              />
            </div>
          </div>
          <div className="lg:order-first lg:row-span-2 pl-5 pr-5">
            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 sm:text-5xl dark:text-zinc-100">
              Thuy Pham, PsyD Consulting Services, PLLC
            </h1>
            <div className="mt-6 space-y-7 text-left text-zinc-600 dark:text-zinc-400">
              <p>
                I'm a Licensed Clinical Psychologist in Tennessee and Illinois.
                I am a member of PsyPact and provide TELEHEALTH THERAPY ONLY. I
                have 24 years of experience providing Cognitive Behavioral
                Therapy for Anxiety and Mood Disorders. I have experience with
                providing supervision for doctoral clinical psychology students.
                In addition I have provided presentations in the community along
                with consultations as needed. In addition I have recently
                completed Level 1 and Level 2 Training in Clinical Hypnosis.
              </p>
              <p>
                I DO NOT ACCEPT INSURANCE BUT CAN PROVIDE A SUPERBILL. I offer a
                free 15 minute consultation to discuss concerns and to assess
                whether my experience and training can assist with meeting your
                needs/goals.{" "}
              </p>
              {/*<div className="text-left">*/}
              {/*  <h3 className="font-bold">Fees</h3>*/}
              {/*  <ul role="list">*/}
              {/*    <li>$150 for 60 minute intake assessment</li>*/}
              {/*    <li>$100 for 50 minute therapy session</li>*/}
              {/*  </ul>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="lg:pl-20 text-left">
            <ul role="list">
              {/*<div>*/}
              {/*    <Button variant="primary" className="mt-6">Schedule a Consultation</Button>*/}
              {/*</div>*/}
              {/*<SocialLink*/}
              {/*    href="mailto:hello@drthuypham.com"*/}
              {/*    icon={MailIcon}*/}
              {/*    className="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40"*/}
              {/*>*/}
              {/*    hello@drthuypham.com*/}
              {/*</SocialLink>*/}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
